import React, { useState, useEffect } from "react"
import Layout from "../retech/components/layout/Layout"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function TermsPage({ data }) {




    const url = typeof window !== 'undefined' ? window.location.href : '';
    const search = typeof window !== 'undefined' ? window.location.search.split("?").pop().replaceAll("%20", " ").toLowerCase() : '';
    const urlimage = typeof window !== 'undefined' ? window.location.hostname : '';


    const Links = data.Links.nodes
    const News = data.News.nodes
    const Article = data.Article.nodes



    const services = data.services.nodes
    const menu = data.menu.nodes
    const products = data.products.nodes


    const terms = data.terms.nodes
    const faq = data.faq.nodes
    const quick = data.quick.nodes






    const [NotFound, setNotFound] = useState(true)


    function NotFoundFun(params) {
        if (NotFound) setNotFound(params)
    }



    return (
        <Layout>
            <Helmet>
                <title>Search</title>


                <meta name="description" content="Search" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content="Search Result" />
                <meta property="og:description" content="Search Result" />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content="Search Result" />
                <meta property="twitter:description" content="Search Result" />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>
            <div className="container-fluid bg-light terms py-5">
                <div className="container">
                    <div className="col-md-12 py-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h1 className="font-weight-bolder">
                                    Search Result
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4">

                {(
                    services.filter(x => x.ServiceItem1DetailTitle?.toLowerCase().includes(search)).length === 0 &&

                    products.filter(x => x.Title?.toLowerCase().includes(search)).length === 0 &&
                    faq.FAQs?.filter(x => x.Question?.toLowerCase().includes(search)).length === 0 &&
                    quick.QuickLinks?.filter(x => x.CTAText?.toLowerCase().includes(search)).length === 0 &&
                    menu.filter(x => x.CTAText?.toLowerCase().includes(search)).length === 0) && <p>No results found, please try a different search query or return <a href='/'>Home</a></p>}



                {services.filter(x => x.ServiceItem1DetailTitle?.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={"/services/" + node.ServiceItemCTALink}><h5 className="font-weight-bolder text-muted">{node.ServiceItem1DetailTitle}</h5></a>
                                <p className="text-muted font-size-small">
                                    {node.ServiceItemDetail1Description}
                                </p>
                            </div>


                        </>
                    )
                })}

                {products.filter(x => x.Title?.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={"/products/" + node.CTALink}><h5 className="font-weight-bolder text-muted">{node.Title}</h5></a>
                                <p className="text-muted font-size-small">
                                    {node.DetailDescription}
                                </p>
                            </div>


                        </>
                    )
                })}
                {/* {terms.map((node, index) => {
                    return (
                        <>
                            {node.Terms?.filter(x => x.TermsTitle.toLowerCase().includes(search)).map(node2 => (
                                <> <div className="d-none">{NotFoundFun(false)}</div>

                                    <div className="col-md-12">


                                        <a href="/terms"><h5 className="font-weight-bolder text-muted">{node.TermsTitle}</h5></a>
                                        <p className="text-muted font-size-small">
                                            {node.TermsDescription}
                                        </p>
                                    </div>


                                </>
                            ))}


                        </>
                    )
                })} */}
                {faq.map((node, index) => {
                    return (
                        <>
                            {node.FAQs?.filter(x => x.Question.toLowerCase().includes(search)).map(node2 => (

                                <> <div className="d-none">{NotFoundFun(false)}</div>

                                    <div className="col-md-12">


                                        <a href="/faq"><h5 className="font-weight-bolder text-muted">{node2.Question}</h5></a>
                                        <p className="text-muted font-size-small">
                                            {node2.Answer}
                                        </p>
                                    </div>


                                </>
                            ))}


                        </>
                    )
                })}
                {quick.map((node, index) => {
                    return (
                        <>
                            {node.QuickLinks?.filter(x => x.CTAText.toLowerCase().includes(search)).map(node2 => (

                                <> <div className="d-none">{NotFoundFun(false)}</div>

                                    <div className="col-md-12">


                                        <a href={node2.CTAText}><h5 className="font-weight-bolder text-muted">{node2.CTAText}</h5></a>

                                    </div>


                                </>
                            ))}


                        </>
                    )
                })}


                {menu.filter(x => x.CTAText.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={node.CTALink}><h5 className="font-weight-bolder text-muted">{node.CTAText}</h5></a>

                            </div>


                        </>
                    )
                })}

                {Links.filter(x => x.Title.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={node.CTALink}><h5 className="font-weight-bolder text-muted">{node.Title}</h5></a>
                                <p className="text-muted font-size-small">
                                    {node.Intro}
                                </p>
                            </div>


                        </>
                    )
                })}
                {News.filter(x => x.Title.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={node.CTALink}><h5 className="font-weight-bolder text-muted">{node.Title}</h5></a>
                                <p className="text-muted font-size-small">
                                    {node.Intro}
                                </p>
                            </div>


                        </>
                    )
                })}
                {Article.filter(x => x.Title.toLowerCase().includes(search)).map((node, index) => {
                    return (

                        <> <div className="d-none">{NotFoundFun(false)}</div>

                            <div className="col-md-12">

                                <a href={node.CTALink}><h5 className="font-weight-bolder text-muted">{node.Title}</h5></a>
                                <p className="text-muted font-size-small">
                                    {node.Intro}
                                </p>
                            </div>


                        </>
                    )
                })}



            </div>
        </Layout >
    )
}
export const query = graphql`
query{
    services:allServiceItemJson {
        nodes {
          ServiceItemCTALink
          ServiceItem1DetailTitle
          ServiceItemDetail1Description
        }
      }
    menu:allMenuJson {
        nodes {
          CTALink
          CTAText
        }
      }
    products: allProductsJson {
        nodes {
          
          CTALink
          Title
          DetailDescription
        }
      }
      Article: allArticlesJson {
        nodes {
      
          Title
         
          Intro
          CTALink
        }
      }
      News: allNewsJson {
        nodes {
      
          Title
         
          Intro
          CTALink
        }
      }

      Links:  allLinksJson {
        nodes {
      
          Title
         
          Intro
          CTALink
        }
      } 
   
  
terms: allTermsJson {
    nodes {
      TermsWidgetIntro
      TermsWidgetTitle
      Terms {
        TermsDescription
        TermsTitle
      }
    }
  }
  faq:allProductsJson {
    nodes {
     
      FAQs {
        Answer
        Question
      }
    }
  }
  quick: allHomeJson {
    nodes {
      QuickLinks {
        CTALink
        CTAText
      }
    }
  }
}
`